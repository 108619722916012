import React from 'react';
import '../styles/About.css';

function About() {
  return(
      <div className='threeCols welcome whiteTxt'>
        <h1 className='welcomeTo'>Welcome to Club Cabbage</h1> <br/>
        <h2 className='description'>A free NFT project whose members will build a thriving decentralized community focused on having fun and being creative.</h2>
        <br/><br/><h2 className='description'>Club Cabbage NFTs are free - just pay gas.</h2><br/><br/>
        <h1 className='welcomeH1'>Together</h1>
        <h2 className='description'>We will make Club Cabbage a welcoming, positive community of creatives, nerds and entrepreneurs.</h2>
      </div>
  );
}

export default About;

