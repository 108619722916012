import React from 'react'

import styled from 'styled-components'
import { Discord, Twitter } from '../components/AllSvgs'


const Icons = styled.div`
display: flex;
flex-direction: column;
align-items: center;

position: fixed;
bottom: 0;
left: 2rem;

z-index: 3;

&>*:not(:last-child){
    margin: 0.5rem 0;
};
`

const Line = styled.span`
width: 2px;
height: 4vh;

background-color: var(--text);
`
const SocialIcons = (props) => {
    return (
        <Icons>
        
            <div>
                <a style={{color:'inherit'}} href="https://discord.gg/aV3sGgm8CN" target='_blank'>
                    <Discord width={25} height={25} />
                </a>
            </div>
        
            <div>
                <a style={{color:'inherit'}} href="https://twitter.com/clubcabbagexyz" target='_blank'>
                    <Twitter width={25} height={25} />
                </a>
            </div>
            
            <Line color={props.theme}/>

        </Icons>
    )
}

export default SocialIcons
