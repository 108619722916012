import React from 'react';
import '../styles/About.css'
import cabbExample1 from '../assets/CabbageExample1.gif';
import cabbExample2 from '../assets/CabbageExample2.gif';


function Examples() {
  return (
    <div className='grid-oneRow'>
        <img src={cabbExample1} className='example1'></img>
        <img src={cabbExample2} className='example1'></img>
        <img src={cabbExample1} className='example1'></img>
        <img src={cabbExample2} className='example2'></img>
        <img src={cabbExample1} className='example2'></img>
        <img src={cabbExample2} className='example2'></img>
    </div>
    );
}

export default Examples;
