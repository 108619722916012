import React, { Component } from 'react';
import './styles/App.css';
import './styles/About.css';
import logoPic from "./assets/Cablogo512.png";
import Minter from './Minter';
import SocialIcons from './subComponents/SocialIcons';
import About from './components/About';
import Tenets from './components/Tenets';
import Examples from './components/Examples';

function App() {
  return (
    <body>
    <div className='grid-container'>
      <div>
        <SocialIcons></SocialIcons>
      </div>
     <div className='headerdiv'>
      <img src={logoPic} alt="Logo" className='logo'/>
      <div className="logowords">
        <h1>club</h1>
      </div>
      <div className="logowords">
        <h1>cabbage</h1>
      </div>
        <div className='threeCols subheading'>
        <h2>Because Everyone Deserves a Little Extra Cabbage.</h2>
        </div>
      </div>
      
      <About></About>
      <div className='threeCols'>
        <Examples></Examples>
      </div>
      <Tenets></Tenets>
    </div>
    <div className='grid-container'>
      <div className='threeCols'>
        
        <Minter></Minter>
      </div>
    </div>
    </body>
  );
}

export default App;
