import React from 'react';
import '../styles/About.css'

function Tenets() {
  return (
      <div className='threeCols grid-container-tenants'>
        <div className='c centerTxt'>
            <h1 className='welcomeH1'>OUR TENETS ARE:</h1>
        </div>
        <div className='threeCols'>
            <h1 className='welcomeH1'>Friendship</h1><br/>
            <h2 className='description'>Club Cabbage members will be incentivised to form lasting friendships. Meet members from around the world who share your interests.</h2> <br/><br/><br/>
            <h1 className='welcomeH1'>Collaboration</h1><br/>
            <h2 className='description'>Club Cabbage members will help each other reach our goals. If you're a songwriter, a game developer, a filmmaker, or an entrepreneur, you can turn to Club Cabbage for feedback, playtesting or to help you build a team.</h2><br/><br/><br/>
            <h1 className='welcomeH1'>Creativity</h1><br/>
            <h2 className='description'>Club Cabbage members believe that everyone has the potential to be creative. Creativity is at the core of being human, we want to create a community that encourages creativity in everything we do.</h2><br/><br/><br/>
        </div>
        <div className='c centerTxt'>
            <a href="https://discord.gg/aV3sGgm8CN" target='_blank' className='welcomeH1'>JOIN THE DISCORD</a> <br/><br/>
            <a href="https://twitter.com/ClubCabbageXYZ" target='_blank' className='welcomeH1'>Follow On Twitter</a> <br/><br/>
        </div>
      </div>
  );
}

export default Tenets;
